import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent, LogoutComponent } from './components/login/login.component'
import { AdmImguploadComponent } from './components/admin/adm-imgupload/adm-imgupload.component';
import { AdminGuard } from './components/admin/adm/adm.guard';
import {StartComponent} from './components/start/start.component';
import { OcsfoComponent } from './components/ocsfo/ocsfo.component';
import { TapComponent } from './components/tap/tap.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'ocsfo', component: OcsfoComponent },
  { path: 'start', component: StartComponent },
  { path: 'tap', component: TapComponent },

  // Главная страница
  {
    path: '',
    loadComponent: () => import('./components/main/main/main.component').then(m => m.MainComponent),
  },
  {
    path: 'main',
    loadComponent: () => import('./components/main/main/main.component').then(m => m.MainComponent),
  },
  {
    path: 'eng',
    loadComponent: () => import('./components/eng/eng.component').then(m => m.EngComponent),
  },
  // Админка
  {
    path: 'adm',
    loadChildren: () => import('./components/admin/adm/adm.module').then(m => m.AdmModule),
    canActivate: [AdminGuard]
  },
  // TODO Загрузка изображений. ВРЕМЕННО!!!
  {
    path: 'imgupload',
    component: AdmImguploadComponent
  },
  // Страницы Static
  {
    path: 'pages', // все материалы страниц
    loadComponent: () => import('./components/static/pages/pages.component').then(m => m.PagesComponent),
  },
  {
    path: 'pages/:id',
    loadComponent: () => import('./components/static/static-page.component').then(m => m.StaticPageComponent),
  },

  // Галерея
  {
    path: 'galleries',
    loadComponent: () => import('./components/gallaries/galeries.component').then(m => m.GaleriesComponent),
  },
  {
    path: 'galleries/:id',
    loadComponent: () => import('./components/gallaries/gallary-details/galeries-details.component').then(m => m.GaleriesDetailsComponent),
  },

  // Центры и отделения
  {
    path: 'centers',
    // loadComponent: () => import('./components/center/centers.component').then(m => m.CentersComponent),
    loadComponent: () => import('./components/centers-new/centers-new.component').then(m => m.CentersNewComponent),
  },
  {
    path: 'centers/:id',
    loadComponent: () => import('./components/center/center-simple/center-simple.component').then(m => m.CenterSimpleComponent),
  },
  {
    path: 'centers/:id/tab/:tab',
    loadComponent: () => import('./components/center/center-simple/center-simple.component').then(m => m.CenterSimpleComponent),
  },
  // Удалено за ненадобностью до момента пока не всплывет необходимость вернуть
  // {
  //   path: 'centers/:unitid/:id',
  //   loadChildren: () => import('./components/center/center-service-details/center-service-details.module').then(m => m.CenterServiceDetailsModule)
  // },

  // Доктора
  {
    path: 'doctors', // страница списка докторов
    loadComponent: () => import('./components/doctors/doctors.component').then(m => m.DoctorsComponent)
  },
  {
    path: 'doctors/:id', // страница описания доктора
    loadComponent: () => import('./components/doctors/doctor-details/doctor-details.component').then(m => m.DoctorDetailsComponent)
  },

  // Услуги
  {
    path: 'services', // страница списка услуг
    loadComponent: () => import('./components/service/services.component').then(m => m.ServicesComponent)
  },
  {
    path: 'services/:id', // страница описания услуги
    loadComponent: () => import('./components/service/service-details/service-details.component').then(m => m.ServiceDetailsComponent)
  },

  // Прейскурант
  {
    path: 'price', // страница прейскуранта
    loadComponent: () => import('./components/price/price.component').then(m => m.PriceComponent)
  },
  {
    path: 'price/:id', // страница описания услуги
    loadComponent: () => import('./components/price/price-details/price-details.component').then(m => m.PriceDetailsComponent)
  },

  // Памятки
  {
    path: 'jottings', // страница списка услуг
    loadComponent: () => import('./components/jottings/jottings.component').then(m => m.JottingsComponent)
  },
  {
    path: 'jottings/:id', // страница описания услуги
    loadComponent: () => import('./components/jottings/jottings-details/jottings-details.component').then(m => m.JottingsDetailsComponent)
  },

  // Отзывы
  {
    path: 'feedbacks',
    loadComponent: () => import('./components/feedbacks/feedbacks.component').then(m => m.FeedbacksComponent)

  },
  {
    path: 'feedbacks/tab/:tab',
    loadComponent: () => import('./components/feedbacks/feedbacks.component').then(m => m.FeedbacksComponent)
  },
  {
    path: 'feedbacks/:id', // Страница полного feedbacks
    // loadChildren: () => import('./components/feedbacks/feedbacks-details/feedbacks-details.component').then(m => m.FeedbacksDetailsModule)
    loadComponent: () => import('./components/feedbacks/feedbacks-details/feedbacks-details.component').then(m => m.FeedbacksDetailsComponent)
  },

  // Вопрос-ответ
  {
    path: 'faq', // список Вопрос-ответов
    loadComponent: () => import('./components/faq/faq.component').then(m => m.FaqComponent)
  },
  {
    path: 'faq/:id', // страница описания Вопрос-ответа
    loadComponent: () => import('./components/faq/faq-details/faq-details.component').then(m => m.FaqDetailsComponent)
  },
  // Страница научного отдела
  {
    path: 'research-unit',
    loadComponent: () => import('./components/research-unit/research-unit.component').then(m => m.ResearchUnitComponent)
  },
  {
    path: 'research-unit/tab/:tab',
    loadComponent: () => import('./components/research-unit/research-unit.component').then(m => m.ResearchUnitComponent)
  },
  // исследования
  {
    path: 'research',
    loadComponent: () => import('./components/research/research.component').then(m => m.ResearchComponent)
  },

  // исследователь
  {
    path: 'researchers/:id',
    loadComponent: () => import('./components/researchers/researchers-details.component').then(m => m.ResearchersDetailsComponent)
  },

  // Страница записи на прием
  {
    path: 'apointment',
    loadChildren: () => import('./components/apointment/apointment.component').then(m => m.ApointmentModule)
    // loadComponent: () => import('./components/apointment/apointment.component').then(m => m.ApointmentComponent)
  },
  // Акции
  {
    path: 'promotions',
    loadComponent: () => import('./components/promotions/promotions.component').then(m => m.PromotionsComponent)
  },
  {
    path: 'promotions/:id',
    loadComponent: () => import('./components/promotions/promotions-details/promotions-details.component').then(m => m.PromotionsDetailsComponent)
  },

  // Страница Контакты
  {
    path: 'contacts',
    loadChildren: () => import('./components/contacts/contacts.component').then(m => m.ContactsModule)
  },
  // Страница результатов поиска
  {
    path: 'search-results',
    loadComponent: () => import('./components/search-results/search-results.component').then(m => m.SearchResultsComponent),
  },

  // Справочник
  {
    path: 'handbooks',
    loadComponent: () => import('./components/handbook/handbook.component').then(m => m.HandbookComponent)
  },
  {
    path: 'handbooks/:id',
    loadComponent: () => import('./components/handbook/handbook-details/handbook-details.component').then(m => m.HandbookDetailsComponent)
  },

  // Клиники
  {
    path: 'clinic', // список клиник
    loadComponent: () => import('./components/clinic/clinics/clinic.component').then(m => m.ClinicComponent)
  },
  {
    path: 'clinic/:id',
    loadComponent: () => import('./components/clinic/clinic-details/clinic-details.component').then(m => m.ClinicDetailsComponent)
  },
  {
    path: 'clinic/:id/tab/:tab',
    loadComponent: () => import('./components/clinic/clinic-details/clinic-details.component').then(m => m.ClinicDetailsComponent)
  },

  // Страница О нас
  {
    path: 'about-center',
    loadComponent: () => import('./components/center/about-center/about-center.component').then(m => m.AboutCenterComponent)
  },
  {
    path: 'about-center/tab/:tab',
    loadComponent: () => import('./components/center/about-center/about-center.component').then(m => m.AboutCenterComponent)
  },

  // Страница Главные внештатные специалисты по СФО
  {
    path: 'main_extraordinary_specialists',
    loadComponent: () => import('./components/main-extraordinary-specialists/main-extraordinary-specialists.component').then(m => m.MainExtraordinarySpecialistsComponent)
  },
  // Страница обращение к генеральному директору
  {
    path: 'OtzivGeneralnomuDirectoru',
    loadComponent: () => import('./components/feedback-to-gendir/feedback-to-gendir.component').then(m => m.FeedbackToGendirComponent)
  },
  // Страница Главные руководители по СФО
  {
    path: 'main_extraordinary_leadership',
    loadComponent: () => import('./components/main-extraordinary-leadership/main-extraordinary-leadership.component').then(m => m.MainExtraordinaryLeadershipComponent)
  },
  // Сотрудники
  {
    path: 'employees', // Список Сотрудников
    loadComponent: () => import('./components/employee/employees.component').then(m => m.EmployeesComponent)
  },
  {
    path: 'employees/:id', // страница описания Сотрудника
    loadComponent: () => import('./components/employee/employee-details/employee-details.component').then(m => m.EmployeeDetailsComponent)
  },

  // Направления
  {
    path: 'directions',  // страница со списком направлений 
    // loadComponent: () => import('./components/direction/directions.component').then(m => m.DirectionsComponent),
    loadComponent: () => import('./components/directions-new/directions-new.component').then(m => m.DirectionsNewComponent),
  },
  {
    path: 'directions/:id', // страница - описание родитеслького направления (содержит вложенные направл.)
    loadComponent: () => import('./components/direction/direction2.component').then(m => m.Direction2Component), // старая версия
  },
  {
    path: 'directions/:id/tab/:tab',
    loadComponent: () => import('./components/direction/direction2.component').then(m => m.Direction2Component), // старая версия
  },
  {
    path: 'dirs/directions/:id', // страница - описание дочернего направления
    loadComponent: () => import('./components/direction/childdirection/child-direction.component').then(m => m.ChildDirectionComponent )
  },
  // Удалено за ненадобностью до момента пока не всплывет необходимость вернуть
  // {
  //   path: 'dirs/directions/:unitid/:id', // страница - Услуги направления
  //   loadChildren: () => import('./components/direction/direction-service-details/direction-service-details.module').then(m => m.DirectionServiceDetailsModule)
  // },
  {
    path: 'dirs/directions/:id/tab/:tab',
    loadComponent: () => import('./components/direction/childdirection/child-direction.component').then(m => m.ChildDirectionComponent )
  },
  // { path: 'direction', component: DirectionComponent}, // пока не ипользуем


  // Аптеки
  {
    path: 'pharmacies',
    loadComponent: () => import('./components/pharmacy/pharmacies/pharmacies.component').then(m => m.PharmaciesComponent)
  },
  {
    path: 'pharmacies/:id',
    loadComponent: () => import('./components/pharmacy/pharmacy.component').then(m => m.PharmacyComponent),
  },
  {
    path: 'pharmacies/:id/tab/:tab',
    loadComponent: () => import('./components/pharmacy/pharmacy.component').then(m => m.PharmacyComponent),
  },

  // Новости
  {
    path: 'news', // список новостей
    // loadComponent: () => import('./components/news/news.component').then(m => m.NewsComponent)
    loadComponent: () => import('./components/news-all/news-all.component').then(m => m.NewsAllComponent)
  },
  {
    path: 'news-mobile', // список новостей (мобильн. версия)
    loadComponent: () => import('./components/news-all-mobile/news-all-mobile.component').then(m => m.NewsAllMobileComponent)
  },
  {
    path: 'news/:id', // Страница описания Новости
    loadComponent: () => import('./components/news/news-details/news-details.component').then(m => m.NewsDetailsComponent)
  },
  // Новости Федеральные
  {
    path: 'federal-news', // список новостей
    // loadComponent: () => import('./components/news/fnews/fnews.component').then(m => m.FnewsComponent)
    loadComponent: () => import('./components/fnews-all/fnews-all.component').then(m => m.FnewsAllComponent)
  },
  {
    path: 'federal-news-mobile', // список новостей (мобильн. версия)
    loadComponent: () => import('./components/fnews-all-mobile/fnews-all-mobile.component').then(m => m.FnewsAllMobileComponent)
  },
  {
    path: 'federal-news/:id', // Страница описания Новости
    loadComponent: () => import('./components/news/fnews-details/fnews-details.component').then(m => m.FnewsDetailsComponent)
  },


  // Статьи
  {
    path: 'articles',
    loadComponent: () => import('./components/articles/articles.component').then(m => m.ArticlesComponent)
  },
  {
    path: 'articles/:id', // описание Статьи
    loadComponent: () => import('./components/articles/articles-details/articles-details.component').then(m => m.ArticlesDetailsComponent)
  },

  // Список Видео
  {
    path: 'videos',
    loadComponent: () => import('./components/news-all/news-all.component').then(m => m.NewsAllComponent) // TODO изменить на страницу с Видео, сейчас это Новости
    // loadComponent: () => import('./components/news/news.component').then(m => m.NewsComponent)
  },

  // error 404
  {
    path: '404',
    loadComponent: () => import('./components/404/404.component').then(m => m.E404Component)
  },
  {
    path: '**',
    loadComponent: () => import('./components/404/404.component').then(m => m.E404Component)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 88] // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
